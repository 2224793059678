/* Nav.js */
.Nav {
}

.Nav__list {
  list-style-type: none;
}

.Nav__button {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1em;
  text-decoration: none;
  box-shadow: 0 0 0 2px white;
  border-radius: 10px;
  font-weight: bold;
  color: #222;
  text-align: left;
  transition: all 50ms linear;
  transform: scale(1);
  outline: 0;
  overflow: hidden;
  box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.1);
}

.Nav__button:hover,
.Nav__button:focus {
  color: black;
  transform: scale(1.05);
  background: white;
  box-shadow: 0 0 0 2px #eee;
  transition: all 75ms linear;
}

.Nav__button img {
  vertical-align: middle;
  border-radius: 6px;
}


.Nav__button:active {
  transition: 0ms;
  box-shadow: 0px 1px 3px rgba(0,0,0,0.33);
  transform: scale(1.05) translateY(2px);
}