/* Header.js */
.Header {
  display: flex;
  flex: initial;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 4rem;
}

.Header__nav {
  margin-top: 1rem;
}