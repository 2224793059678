/* move along nothing to see here */

.App {
  /*display: flex;*/
  /*flex-direction: column;
  align-items: stretch;*/
  color: white;
  padding-top: 5%;
  font-family: 'Open Sans', sans-serif;
}

.App__content {
  width: 100%;
  max-width: 100%;
  width: 350px;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
}

