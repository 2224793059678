
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-size: 16px;
  font-family: sans-serif;
  background: #0088f9;
  background: #fff;
  line-height: 1em;
}

h1, h2, h3, h4, h5, h6,
.heading {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 1.4em;
}

#root {
  height: 100%;
}

:root {
 --color-a: #0088f9;
 --color-b: #8400ff;
 --color-c: #fcca45;
 --color-d: #e21640;
 --color-e: rgb(2, 46, 87);
}
