@import url('https://fonts.googleapis.com/css?family=Quicksand|Roboto');


.DiscoPage {
  font-family: 'Roboto';
  background: #0c0a01 url('../images/discobg.png') top left repeat;
  background-size: 256px 256px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.DiscoPage h2 {
  font-family: 'Quicksand';
  font-weight: 600;
  opacity: 0.87;
}

.DiscoPage__logo {
  opacity: 0;

  /* @keyframes duration | timing-function | delay | 
iteration-count | direction | fill-mode | play-state | name */
  animation: 3s cubic-bezier(.17,.8,.32,1) 0.2s forwards rotateIn;

}

@keyframes rotateIn {
  0% {
    transform: rotate(-90deg) scale(0.9);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
}