@import url(https://fonts.googleapis.com/css?family=Quicksand|Roboto);
/* Header.js */
.Header {
  display: flex;
  flex: initial;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 4rem;
}

.Header__nav {
  margin-top: 1rem;
}
/* Nav.js */
.Nav {
}

.Nav__list {
  list-style-type: none;
}

.Nav__button {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1em;
  text-decoration: none;
  box-shadow: 0 0 0 2px white;
  border-radius: 10px;
  font-weight: bold;
  color: #222;
  text-align: left;
  transition: all 50ms linear;
  transform: scale(1);
  outline: 0;
  overflow: hidden;
  box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.1);
}

.Nav__button:hover,
.Nav__button:focus {
  color: black;
  transform: scale(1.05);
  background: white;
  box-shadow: 0 0 0 2px #eee;
  transition: all 75ms linear;
}

.Nav__button img {
  vertical-align: middle;
  border-radius: 6px;
}


.Nav__button:active {
  transition: 0ms;
  box-shadow: 0px 1px 3px rgba(0,0,0,0.33);
  transform: scale(1.05) translateY(2px);
}
.DiscoPage {
  font-family: 'Roboto';
  background: #0c0a01 url(/static/media/discobg.f289f15a.png) top left repeat;
  background-size: 256px 256px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.DiscoPage h2 {
  font-family: 'Quicksand';
  font-weight: 600;
  opacity: 0.87;
}

.DiscoPage__logo {
  opacity: 0;

  /* @keyframes duration | timing-function | delay | 
iteration-count | direction | fill-mode | play-state | name */
  -webkit-animation: 3s cubic-bezier(.17,.8,.32,1) 0.2s forwards rotateIn;
          animation: 3s cubic-bezier(.17,.8,.32,1) 0.2s forwards rotateIn;

}

@-webkit-keyframes rotateIn {
  0% {
    transform: rotate(-90deg) scale(0.9);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
}

@keyframes rotateIn {
  0% {
    transform: rotate(-90deg) scale(0.9);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
}
/* move along nothing to see here */

.App {
  /*display: flex;*/
  /*flex-direction: column;
  align-items: stretch;*/
  color: white;
  padding-top: 5%;
  font-family: 'Open Sans', sans-serif;
}

.App__content {
  width: 100%;
  max-width: 100%;
  width: 350px;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
}



* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-size: 16px;
  font-family: sans-serif;
  background: #0088f9;
  background: #fff;
  line-height: 1em;
}

h1, h2, h3, h4, h5, h6,
.heading {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 1.4em;
}

#root {
  height: 100%;
}

:root {
 --color-a: #0088f9;
 --color-b: #8400ff;
 --color-c: #fcca45;
 --color-d: #e21640;
 --color-e: rgb(2, 46, 87);
}

